import axios, { AxiosResponse } from 'axios';

import { environmentVariables } from 'constants/variables';
import {
    UserEmailLoginModel,
    UserRegisterModel,
    SSOModel,
    UserPhoneLoginModel,
    RhythmicGymnasticsEventModel,
    EventTypeFilter,
} from './models';
import {
    RhythmicGymnasticsEventRegistrationModel,
    RhythmicGymnasticsEventGroupRegistrationModel,
    RhythmicGymnasticsEventGroupMember,
} from 'components/events/interfaces';

axios.defaults.baseURL = environmentVariables.baseURL;

const IPAPI_URL = 'https://ipapi.co/country_name';
const jsonHeader = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
};
const TEN_YEARS = 31556926000 * 10;

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string, params?: {}) =>
        axios.get(url, params).then(responseBody),
    post: (url: string, body: {} = {}) =>
        axios.post(url, body, jsonHeader).then(responseBody),
    put: (url: string, body: {} = {}) =>
        axios.put(url, body, jsonHeader).then(responseBody),
    delete: (url: string, data?: {}) =>
        axios
            .delete(url, {
                data,
            })
            .then(responseBody),
};

const global = {
    getLocationCountry: () => requests.get(IPAPI_URL, jsonHeader),
    getCountryFlag: (country: string) =>
        requests.get(`https://countryflagsapi.com/svg/${country}`),
};

const account = {
    registerUser: (model: UserRegisterModel) =>
        requests.put('/account/register', model),
    confirmEmail: (urlParams: URLSearchParams) =>
        requests.post(`account/confirm-email?${urlParams}`),
    logInUser: (model: UserEmailLoginModel) =>
        requests.post('/account/login', model),
    logInUserWithPhoneNumber: (model: UserPhoneLoginModel) =>
        requests.post('/account/login-with-phone', model),
    logOutUser: () => requests.post('/account/logout'),
    getData: () => requests.get('/account/get-data'),
    sSO: (model: SSOModel) => requests.post('/account/sso', model),
    forgotPassword: (email: string) =>
        requests.post(`account/forgot-password?`, { email: email }),
    resetPassword: (urlParams: URLSearchParams, password: string) =>
        requests.post(`account/reset-password?${urlParams}`, {
            password: password,
        }),
};

const events = {
    createEvent: (model: RhythmicGymnasticsEventModel) =>
        requests.put('/events/rhytmic-gymnasty', model),
    getEvents: (
        type: EventTypeFilter = 'all',
        dateFrom: string = '',
        dateTo: string = '',
        country: string = '',
        city: string = ''
    ) =>
        requests.get(
            '/events/rhytmic-gymnasty?' +
                new URLSearchParams({
                    type: type,
                    dateFrom: new Date(dateFrom ? dateFrom : 0).toUTCString(),
                    dateTo: new Date(
                        dateTo ? dateTo : Date.now() + TEN_YEARS
                    ).toUTCString(),
                    country: country,
                    city: city,
                })
        ),
    getEventDetails: (id: string) =>
        requests.get(`/events/rhytmic-gymnasty/${id}`),
    getUserEvents: () => requests.get('/events/my-rhytmic-gymnasty'),
    updateEvent: (id: string, model: RhythmicGymnasticsEventModel) =>
        requests.post(`/events/rhytmic-gymnasty/${id}`, model),
    postEvent: (id: string) => requests.put(`/events/rhytmic-gymnasty/${id}`),
    deleteEvent: (id: string) =>
        requests.delete(`/events/rhytmic-gymnasty/${id}`),
    getNotPublishedEvents: (
        type: EventTypeFilter = 'all',
        dateFrom: string = '',
        dateTo: string = '',
        country: string = '',
        city: string = ''
    ) =>
        requests.get(
            '/events/rhytmic-gymnasty/not-published?' +
                new URLSearchParams({
                    type: type,
                    dateFrom: new Date(dateFrom ? dateFrom : 0).toUTCString(),
                    dateTo: new Date(
                        dateTo ? dateTo : Date.now() + TEN_YEARS
                    ).toUTCString(),
                    country: country,
                    city: city,
                })
        ),
    registerRhytmicGynastParticipiant: (
        id: string,
        model: RhythmicGymnasticsEventRegistrationModel
    ) => requests.put(`/events/rhytmic-gymnasty/${id}/participiant`, model),
    removeRhytmicGynastParticipiantFromCategory: (
        eventId: string,
        psrticipiantId: string,
        categoryId: string
    ) =>
        requests.delete(
            `/events/rhytmic-gymnasty/${eventId}/participiant/${psrticipiantId}/category/${categoryId}`
        ),
    updateRhytmicGynastParticipiant: (
        id: string,
        participiantId: string,
        model: RhythmicGymnasticsEventRegistrationModel
    ) =>
        requests.post(
            `/events/rhytmic-gymnasty/${id}/participiant/${participiantId}`,
            model
        ),
    registerRhytmicGymastTeam: (
        id: string,
        model: RhythmicGymnasticsEventGroupRegistrationModel
    ) => requests.put(`/events/rhytmic-gymnasty/${id}/team`, model),
    registerRhytmicGymastTeamMembers: (
        eventId: string,
        teamId: string,
        members: RhythmicGymnasticsEventGroupMember[]
    ) =>
        requests.put(
            `/events/rhytmic-gymnasty/${eventId}/team/${teamId}/members`,
            members
        ),
    getMyGymnasts: (lastName: string) =>
        requests.get(`/events/my-gymnasts?lastName=${lastName}`),
};

const files = {
    uploadEventLogo: (eventId: string, data: FormData) =>
        requests.put(`/file/event-logo?eventId=${eventId}`, data),
    updateEventLogo: (eventId: string, data: FormData) =>
        requests.post(`/file/event-logo?eventId=${eventId}`, data),
    deleteEventLogo: (eventId: string) =>
        requests.delete(`/file/event-logo?eventId=${eventId}`),
    uploadEventDocuments: (eventId: string, data: FormData) =>
        requests.put(`/file/event-documents?eventId=${eventId}`, data),
    downloadEventDocument: (eventId: string, documentName: string) =>
        axios
            .get(
                `/file/event-documents?eventId=${eventId}&documentName=${documentName}`,
                { responseType: 'blob' }
            )
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(
                    new Blob([data])
                );
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.dispatchEvent(
                    new TouchEvent('touchstart', {
                        bubbles: false,
                        cancelable: true,
                        view: window,
                    })
                );
                link.dispatchEvent(
                    new TouchEvent('touchend', {
                        bubbles: false,
                        cancelable: true,
                        view: window,
                    })
                );
                link.click();
                link.remove();
            }),
    deleteEventDocuments: (eventId: string, documents: string[]) =>
        requests.delete(
            `/file/event-documents?eventId=${eventId}${documents
                .map((x) => '&documents=' + x)
                .join('')}`
        ),
    uploadProfilePhoto: (userId: string, data: FormData) =>
        requests.put(`/file/profile-photo?userId=${userId}`, data),
    uploadParticipiantMediaFiles: (
        eventId: string,
        categoryId: string,
        participiantId: string,
        data: FormData
    ) =>
        requests.put(
            `/file/participiant-media?eventId=${eventId}&categoryId=${categoryId}&participiantId=${participiantId}`,
            data
        ),
    uploadTeamMediaFiles: (
        eventId: string,
        categoryId: string,
        teamId: string,
        data: FormData
    ) =>
        requests.put(
            `/file/team-media?eventId=${eventId}&categoryId=${categoryId}&teamId=${teamId}`,
            data
        ),
    downloadEventMediaFiles: (eventName: string) =>
        axios
            .get(`/file/download-event-mediafiles?eventName=${eventName}`, {
                responseType: 'blob',
            })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(
                    new Blob([data])
                );
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${eventName}.zip`);
                document.body.appendChild(link);
                link.dispatchEvent(
                    new TouchEvent('touchstart', {
                        bubbles: false,
                        cancelable: true,
                        view: window,
                    })
                );
                link.dispatchEvent(
                    new TouchEvent('touchend', {
                        bubbles: false,
                        cancelable: true,
                        view: window,
                    })
                );
                link.click();
                link.remove();
            }),
    downloadEventEntriesList: (eventName: string, eventId: string) =>
        axios
            .get(
                `/file/download-event-entries?eventName=${eventName}&eventId=${eventId}`,
                {
                    responseType: 'blob',
                }
            )
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(
                    new Blob([data])
                );
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${eventName}.xlsx`);
                document.body.appendChild(link);
                link.dispatchEvent(
                    new TouchEvent('touchstart', {
                        bubbles: false,
                        cancelable: true,
                        view: window,
                    })
                );
                link.dispatchEvent(
                    new TouchEvent('touchend', {
                        bubbles: false,
                        cancelable: true,
                        view: window,
                    })
                );
                link.click();
                link.remove();
            }),
};

const api = {
    requests,
    global,
    account,
    events,
    files,
};

export default api;
