import { Action, Reducer } from 'redux';

import { EventsActions } from './actions';
import {
    RhythmicGymnasticsEvent,
    RhythmicGymnasticsEventDetailsEdit,
} from 'components/events/interfaces';
import { EventType, RhytmicGymnasticsEventStatus } from 'constants/enums';

const defaultEventDetails: RhythmicGymnasticsEventDetailsEdit = {
    id: '',
    type: EventType.Live,
    status: RhytmicGymnasticsEventStatus.Inactive,
    title: '',
    country: '',
    logoName: '',
    place: '',
    organizer: '',
    startDate: new Date(),
    endDate: new Date(),
    registrationDeadline: new Date(),
    audioDeadline: new Date(),
    contacts: '',
    logo: '',
    notes: '',
    documents: [],
    categories: [],
    participiants: [],
    teams: [],
};

export interface EventsState {
    showNotPublished: boolean;
    events: RhythmicGymnasticsEvent[];
    eventDetails: RhythmicGymnasticsEventDetailsEdit;
}

const defaultState: EventsState = {
    showNotPublished: false,
    events: [],
    eventDetails: defaultEventDetails,
};

export const reducer: Reducer<EventsState> = (
    state: EventsState | undefined,
    incomingAction: Action
): EventsState => {
    if (state === undefined) {
        return defaultState;
    }

    const action = incomingAction as EventsActions;
    switch (action.type) {
        case 'EVENTS_GET_DATA_SUCCESS':
            return { ...state, events: action.payload };
        case 'EVENTS_ADD_NEW_EVENT':
            return { ...state, events: [...state.events, action.payload] };
        case 'EVENTS_SET_SHOW_NOT_PUBLISHED_EVENTS':
            return { ...state, showNotPublished: action.payload };
        case 'EVENTS_GET_EVENT_DETAILS_SUCCESS':
            return { ...state, eventDetails: action.payload };
        case 'EVENTS_POST_EVENT_SUCCESS':
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    status: +action.payload,
                },
            };
        case 'EVENTS_DELETE_EVENT_SUCCESS':
            return {
                ...state,
                eventDetails: defaultEventDetails,
            };
        case 'EVENTS_UPDATE_PARTICIPIANT_SUCCESS': {
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    participiants: state.eventDetails.participiants.map(
                        (part) =>
                            part.id == action.payload.id ? action.payload : part
                    ),
                },
            };
        }
        case 'EVENTS_REMOVE_PARTICIPIANT_FROM_CATEGORY_SUCCESS': {
            const updatedParts = [...state.eventDetails.participiants];
            const part = updatedParts.find(
                (part) =>
                    part.id === action.payload.participiantId &&
                    part.categoryId === action.payload.categoryId
            );
            if (part) {
                const indexToRemove = updatedParts.indexOf(part);
                updatedParts.splice(indexToRemove, 1);
            }
            return {
                ...state,
                eventDetails: {
                    ...state.eventDetails,
                    participiants: [...updatedParts],
                },
            };
        }
    }

    return state;
};
